/**
 * Sorts an array of objects by a given property
 * @param property - property to sort by
 * @returns 
 */
export const dynamicSort = (property: string) => {
  let sortOrder = 1
  if (property === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: { [key: string]: any }, b: { [key: string]: any }) {
    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}

/**
 * Filters an array to only unique values
 * @param value 
 * @param index 
 * @param self 
 * @returns 
 */
export const filterOnlyUnique = (value: string, index: number, self: any) => {
  return self.indexOf(value) === index
}

/**
 * Sort an array in ascending order
 * @param a 
 * @param b 
 * @returns 
 */
export const sortAscending = (a: any, b: any) => {
  if (a < b) { return -1 }
  if (a > b) { return 1 }
  return 0
}

/**
 * Formats a number with thousands separators and a specified number of decimal places.
 * @param numDecimals - The number of decimal places to round to. Defaults to 2 if not provided.
 * @param groupSize - The number of digits to group by. Defaults to 3 if not provided.
 * @param thousandsSeparator - The character to use as the thousands separator. Defaults to a space if not provided.
 * @param decimalPoint - The character to use as the decimal point. Defaults to a comma if not provided.
 * @returns The formatted number as a string.
 */
export const formatPrice = (
  price: number,
  numDecimals: number = 2,
  groupSize: number = 3,
  thousandsSeparator: string = ' ',
  decimalPoint: string = ','
): string => {
  const regex = `\\d(?=(\\d{${groupSize}})+${numDecimals > 0 ? '\\D' : '$'})`
  const formattedNum = price.toFixed(Math.max(0, ~~numDecimals))
    .replace('.', decimalPoint)
    .replace(new RegExp(regex, 'g'), '$&' + thousandsSeparator)

  return formattedNum
}

/**
 * Converts an array of strings to a good URL by concatenating the strings with hyphens,
 * replacing non-alphanumeric characters with hyphens, replacing Swedish characters with
 * their ASCII equivalents, and converting the resulting string to lowercase.
 * 
 * @param {string[]} texts - The array of strings to convert to a URL.
 * @returns {string} The converted URL.
 */
export const convertToURL = (texts: string[]): string => {
  const combinedText = texts.join('-')
  const replacedText = combinedText
    .replace(/[^a-zA-Z0-9ÄÅÖäåö]+/g, '-')
    .replace(/[ÄÅäå]/g, 'a')
    .replace(/[Öö]/g, 'o')
  const trimmedText = replacedText.replace(/^-+|-+$/g, '')
  const lowercasedText = trimmedText.toLowerCase()

  return lowercasedText
}

/**
 * 
 * @param title 
 * @returns 
 */
export const getPageTitle = (title: string) => {
  if (title.length === 0)
    return 'Kvalitet & Service hos Statusfälgar'

  return `${title} | Kvalitet & Service hos Statusfälgar`
}

/**
 * Converts new lines to <br> tags
 * @param {string} str
 * @returns {string}
 */
export const nl2br = (str: string): string => {
  if (typeof str === 'undefined' || str === null) return ''

  return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

/**
 * Validates a Swedish identity number (personnummer) using the Luhn algorithm.
 * @param {string} value - The input string to validate.
 * @returns {boolean} - Returns true if the input string is a valid Swedish identity number, otherwise false.
 */
export const isValidSwedishIdentityNumber = (value: string): boolean => {
  var number = value.replace("-", "").replace(" ", "").trim()

  // if 12 digits, remove the first two
  if (number.length == 12)
    number = number.substring(2)

  if (number.length != 10)
    return false

  return isValidLuhnNumber(number)
}

/**
 * Validates a number using the Luhn algorithm.
 * @param {string} value - The input string to validate.
 * @returns {boolean} - Returns true if the input string passes the Luhn algorithm check, otherwise false.
 */
export const isValidLuhnNumber = (value: string): boolean => {
  if (/[^0-9-]+/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  var nCheck = 0, nDigit = 0, bEven = false
  value = value.replace(/\D/g, "")

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9
    }

    nCheck += nDigit
    bEven = !bEven
  }

  return (nCheck % 10) == 0
}

/**
 * Converts a string to a number
 * @param input - The string to convert to a number
 * @returns The converted number, or null if the input string is null, undefined, or empty.
 */
export const stringToNumber = (input: string | null | undefined): number | undefined => {
  if (input === null || input === undefined || input.trim() === '') {
    return undefined
  }

  const number = Number(input)
  return isNaN(number) ? undefined : number
}

/**
 * Builds a query string from the given parameters object.
 * Filters out properties with empty values (null, undefined, or empty string).
 * 
 * @template T - A generic object type extending Record<string, any>
 * @param {T} params - The parameters object to be converted into a query string.
 * @returns {string} - A query string starting with '?' if parameters exist, or an empty string otherwise.
 */
export const buildQueryString = <T extends Record<string, any>>(params: T): string => {
  const query = Object.keys(params)
    .filter(key => {
      const value = params[key]
      // Check if the value is not null, undefined, or an empty string
      return value !== null && value !== undefined && value !== ''
    })
    .map(key => {
      const value = params[key]
      return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    })
    .join('&')
  return query ? `${query}` : ''
}